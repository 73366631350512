import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  DirectApplyCreators,
  DirectApplyTypes,
} from "src/redux/actions";
import Loader from "react-loader-spinner";
import EventBus from "eventing-bus";

const UploadCialfoDocument = props => {
  const { uploadCialfoDocument, t, getApplication, id } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const documentUploadSuccess = EventBus.on(
      DirectApplyTypes.UPLOAD_CIALFO_DOCUMENT_SUCCESS,
      () => {
        setUploading(false);
        setIsOpen(false);
        setFile(null);
        getApplication(id);
      },
    );
    return () => {
      documentUploadSuccess();
    };
  }, []);

  return (
    <>
      <div>
        <button
          className="btn-text-primary"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <FontAwesomeIcon
            icon={["fas", "upload"]}
            className="mr-1"
          />
          Upload Document
        </button>
      </div>
      {isOpen && (
        <ReactModal
          isOpen={isOpen}
          style={{
            content: {
              width: "500px",
              margin: "200px auto",
              outline: "none",
            },
          }}
          overlayClassName="modal-overlay"
          className="modal-content"
        >
          <div className="bg-white p-8 rounded-md">
            <div
              className={
                "flex items-center flex-row justify-between mb-4"
              }
            >
              <span className="font-bold text-black text-xl">
                Upload
              </span>

              <FontAwesomeIcon
                onClick={() => {
                  setIsOpen(false);
                  setUploading(false);
                  setFile(null);
                }}
                icon={["fas", "times"]}
                className={
                  "text-lg leading-none text-gray-750 cursor-pointer"
                }
              />
            </div>

            {file ? (
              <div className="flex-1 flex flex-row text-blue-700 border-t border-b border-gray-300">
                <div className="flex-none flex w-10 justify-center items-center bg-blue-50">
                  <FontAwesomeIcon
                    icon={["fas", "file-pdf"]}
                    className={"text-2xl leading-none"}
                  />
                </div>
                <div className="flex-1 flex flex-col justify-center ml-3 py-2">
                  <p className="text-xs mb-0 truncate w-300px">
                    {file.name}
                  </p>
                </div>
                {uploading ? (
                  <div className="flex-none flex w-10 justify-center items-center">
                    <Loader
                      type="Oval"
                      color={"#007BE2"}
                      height={20}
                      width={20}
                    />
                  </div>
                ) : (
                  <div
                    onClick={() => setFile(null)}
                    className="flex-none flex w-10 h-10 justify-center items-center cursor-pointer"
                  >
                    <FontAwesomeIcon
                      icon={["fas", "trash-can"]}
                      className={"text-lg text-gray-750 leading-none"}
                    />
                  </div>
                )}
              </div>
            ) : (
              <>
                <input
                  accept=".pdf"
                  name="document"
                  id="document"
                  type="file"
                  className={"hidden"}
                  onChange={e => {
                    setError(null);
                    const file = e.target.files[0];
                    if (file.size / 1024 / 1024 < 5) {
                      setFile(e.target.files[0]);
                    } else {
                      setError("File size should be less than 5 MB");
                    }
                  }}
                />
                <label htmlFor="document" className="cursor-pointer">
                  <div
                    className={
                      "flex justify-center items-center h-32 bg-blue-50 border border-dashed border-blue-700 mt-3"
                    }
                  >
                    <div
                      className={
                        "btn-base text-blue-700 cursor-pointer"
                      }
                    >
                      <FontAwesomeIcon
                        icon={["fas", "upload"]}
                        className="mr-1"
                      />
                      {t(
                        "direct_apply_programs.upload_document.upload_document",
                      )}
                    </div>
                  </div>
                </label>
              </>
            )}
            {error && (
              <div className="text-red-800 text-sm font-bold mt-2">
                {error}
              </div>
            )}
            <div className="flex flex-none justify-end mt-4">
              <button
                disabled={!file}
                onClick={() => {
                  setUploading(true);
                  uploadCialfoDocument(id, {
                    file,
                    file_name: file.name,
                  });
                }}
                className="btn-primary"
              >
                <span className="">Upload</span>
              </button>
            </div>
          </div>
        </ReactModal>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {
  uploadCialfoDocument:
    DirectApplyCreators.uploadCialfoDocumentRequest,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(UploadCialfoDocument),
);
