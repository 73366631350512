import { createReducer } from "reduxsauce";
import { DirectApplyTypes as Types, UserTypes } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.directApply;

export const clearApplications = (state, action) => ({
  ...state,
  data: [],
});
export const clearTransactions = (state, action) => ({
  ...state,
  transactions: [],
});
export const clearTransaction = (state, action) => ({
  ...state,
  selected: null,
});
export const clearRefundActivities = (state, action) => ({
  ...state,
  refundActivities: {},
});
export const clearPrograms = (state, action) => ({
  ...state,
  programs: [],
});

export const getApplicationFailure = (state, action) => ({
  ...state,
  loadingSelected: false,
});
export const getApplicationRequest = (state, action) => ({
  ...state,
  loadingSelected: true,
  selected: null,
});
export const getApplicationSuccess = (state, action) => ({
  ...state,
  loadingSelected: false,
  selected: action.application,
});

export const updateApplicationUcasDataFailure = (state, action) => ({
  ...state,
});
export const updateApplicationUcasDataRequest = (state, action) => ({
  ...state,
});
export const updateApplicationUcasDataSuccess = (state, action) => ({
  ...state,
});

export const updatedUcasCredentialFailure = (state, action) => ({
  ...state,
});
export const updatedUcasCredentialRequest = (state, action) => ({
  ...state,
});
export const updatedUcasCredentialSuccess = (state, action) => {
  const { data } = action;

  return {
    ...state,
    selected: {
      ...state.selected,
      is_ucas_credential_updated: data.is_ucas_credential_updated,
    },
  };
};

export const getFollowUpsFailure = (state, action) => ({
  ...state,
});
export const getFollowUpsRequest = (state, action) => ({
  ...state,
});
export const getFollowUpsSuccess = (state, action) => {
  const { followUps } = action;

  return {
    ...state,
    followUpsList: followUps,
  };
};

export const getDocumentListFailure = (state, action) => ({
  ...state,
});
export const getDocumentListRequest = (state, action) => ({
  ...state,
});
export const getDocumentListSuccess = (state, action) => {
  const { documents } = action;

  return {
    ...state,
    documentsList: documents,
  };
};

export const addFollowUpFailure = (state, action) => ({
  ...state,
  isFollowUpLoading: false,
});
export const addFollowUpRequest = (state, action) => ({
  ...state,
  isFollowUpLoading: true,
});
export const addFollowUpSuccess = (state, action) => ({
  ...state,
  isFollowUpLoading: false,
});

export const uploadCounselorDocumentFailure = (state, action) => ({
  ...state,
});
export const uploadCounselorDocumentRequest = (state, action) => ({
  ...state,
});
export const uploadCounselorDocumentSuccess = (state, action) => ({
  ...state,
});

export const uploadCialfoDocumentFailure = (state, action) => ({
  ...state,
});
export const uploadCialfoDocumentRequest = (state, action) => ({
  ...state,
});
export const uploadCialfoDocumentSuccess = (state, action) => ({
  ...state,
});

export const deleteCialfoDocumentFailure = (state, action) => ({
  ...state,
});
export const deleteCialfoDocumentRequest = (state, action) => ({
  ...state,
});
export const deleteCialfoDocumentSuccess = (state, action) => ({
  ...state,
});

export const getApplicationsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getApplicationsRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getApplicationsSuccess = (state, action) => {
  const {
    applications,
    matched,
    total,
    pastTotal,
    params,
    studentsCount,
  } = action;

  return {
    ...state,
    data:
      params.page_no === 1
        ? applications
        : [...state.data, ...applications],
    matched,
    total,
    pastTotal,
    params: { ...state.params, ...params },
    studentsCount,
    loading: false,
  };
};

export const getDirectApplyFilterCountriesFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterCountriesRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterCountriesSuccess = (
  state,
  action,
) => ({
  ...state,
  countries: action.countries,
});

export const getDirectApplyFilterCoursesFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterCoursesRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterCoursesSuccess = (
  state,
  action,
) => ({
  ...state,
  courses: action.courses,
});

export const getDirectApplyFilterProgramsFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterProgramsRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterProgramsSuccess = (
  state,
  action,
) => ({
  ...state,
  programs: action.programs,
});

export const getDirectApplyFilterResultsFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterResultsRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterResultsSuccess = (
  state,
  action,
) => ({
  ...state,
  results: action.results,
});

export const getDirectApplyFilterStatusFailure = (state, action) => ({
  ...state,
});
export const getDirectApplyFilterStatusRequest = (state, action) => ({
  ...state,
});
export const getDirectApplyFilterStatusSuccess = (state, action) => ({
  ...state,
  status: action.status,
});

export const getDirectApplyFilterFeeStatusFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterFeeStatusRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterFeeStatusSuccess = (
  state,
  action,
) => ({
  ...state,
  feeStatus: action.statuses,
});

export const getDirectApplyFilterFeeWaivedFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterFeeWaivedRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterFeeWaivedSuccess = (
  state,
  action,
) => ({
  ...state,
  feeWaived: action.statuses,
});

export const getDirectApplyFilterSchoolsFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterSchoolsRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterSchoolsSuccess = (
  state,
  action,
) => ({
  ...state,
  schools: action.schools,
});

export const getDirectApplyFilterUniversitiesFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterUniversitiesRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterUniversitiesSuccess = (
  state,
  action,
) => ({
  ...state,
  universities: action.universities,
});

export const getDirectApplyFilterTransactionsUniversitiesFailure = (
  state,
  action,
) => ({ ...state });
export const getDirectApplyFilterTransactionsUniversitiesRequest = (
  state,
  action,
) => ({ ...state });
export const getDirectApplyFilterTransactionsUniversitiesSuccess = (
  state,
  action,
) => ({ ...state, transactionsUniversities: action.universities });

export const getDirectApplyListDownloadUrlFailure = (
  state,
  action,
) => ({
  ...state,
  downloadUrl: null,
});
export const getDirectApplyListDownloadUrlRequest = (
  state,
  action,
) => ({
  ...state,
  downloadUrl: null,
});
export const getDirectApplyListDownloadUrlSuccess = (
  state,
  action,
) => ({
  ...state,
  downloadUrl: action.url,
});

export const getTransactionsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getTransactionsRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getTransactionsSuccess = (state, action) => {
  const { transactions, matched, total, params } = action;
  return {
    ...state,
    transactions:
      params.page_no === 1
        ? transactions
        : [...state.transactions, ...transactions],
    matched,
    total,
    transactionsParams: { ...state.transactionsParams, ...params },
    loading: false,
  };
};

export const getTransactionFailure = (state, action) => ({
  ...state,
  isFetchingTransaction: false,
  transcationFetchSuccessful: false,
  transaction: null,
});
export const getTransactionRequest = (state, action) => ({
  ...state,
  isFetchingTransaction: true,
  transcationFetchSuccessful: false,
});
export const getTransactionSuccess = (state, action) => ({
  ...state,
  isFetchingTransaction: false,
  transcationFetchSuccessful: true,
  transaction: action.transaction,
});

export const refundFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const refundRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const refundSuccess = (state, action) => {
  const { refund } = action;

  let transactions = [];

  if (refund.activities.length === 0) {
    transactions = state.transactions;
  }

  return {
    ...state,
    transactions: transactions,
    refundActivities: refund,
    loading: false,
  };
};

export const getProgramsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getProgramsRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getProgramsSuccess = (state, action) => {
  const { programs, matched, total, params } = action;

  return {
    ...state,
    matched,
    total,
    programs:
      params.page_no === 1
        ? programs
        : [...state.programs, ...programs],
    params,
    loading: false,
    shouldReloadPrograms: false,
  };
};

export const getDirectApplyFilterPartnersFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterPartnersRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterPartnersSuccess = (
  state,
  action,
) => ({
  ...state,
  partners: action.partners,
});

export const getDirectApplyFilterProgramTypesFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterProgramTypesRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterProgramTypesSuccess = (
  state,
  action,
) => ({
  ...state,
  programTypes: action.programTypes,
});

export const getDirectApplyFilterProgramSubmissionTypesFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterProgramSubmissionTypesRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterProgramSubmissionTypesSuccess = (
  state,
  action,
) => ({
  ...state,
  programSubmissionTypes: action.programSubmissionTypes,
});

export const updateApplicationStatusFailure = (state, action) => ({
  ...state,
});
export const updateApplicationStatusRequest = (state, action) => ({
  ...state,
});
export const updateApplicationStatusSuccess = (state, action) => ({
  ...state,
  selected: {
    ...state.selected,
    ...action.updatedApplicationAttributes,
  },
});

export const updateApplicationResultFailure = (state, action) => ({
  ...state,
});
export const updateApplicationResultRequest = (state, action) => ({
  ...state,
});
export const updateApplicationResultSuccess = (state, action) => ({
  ...state,
  selected: {
    ...state.selected,
    ...action.updatedApplicationAttributes,
  },
});

export const updateApplicationDepositPaidFailure = (
  state,
  action,
) => ({
  ...state,
});
export const updateApplicationDepositPaidRequest = (
  state,
  action,
) => ({
  ...state,
});
export const updateApplicationDepositPaidSuccess = (
  state,
  action,
) => ({
  ...state,
  selected: {
    ...state.selected,
    ...action.updatedApplicationAttributes,
  },
});

export const getDirectApplicationResultsFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplicationResultsRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplicationResultsSuccess = (
  state,
  action,
) => ({
  ...state,
  applicationResults: action.results,
});

export const getDirectApplicationStatusListFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplicationStatusListRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplicationStatusListSuccess = (
  state,
  action,
) => ({
  ...state,
  statusList: action.statuses,
});

export const getDirectApplicationResultListFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplicationResultListRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplicationResultListSuccess = (
  state,
  action,
) => ({
  ...state,
  resultList: action.results,
});

export const deleteDirectApplicationDocumentFailure = (
  state,
  action,
) => ({
  ...state,
  loading: false,
});
export const deleteDirectApplicationDocumentRequest = (
  state,
  action,
) => ({
  ...state,
  loading: true,
});
export const deleteDirectApplicationDocumentSuccess = (
  state,
  action,
) => {
  const {
    selected: { university_documents },
  } = JSON.parse(JSON.stringify(state));
  let doc = university_documents.find(x => x.id === action.id);
  doc.is_deleted = true;
  return {
    ...state,
    selected: { ...state.selected, university_documents },
    loading: false,
  };
};

export const getProgramsListDownloadUrlFailure = (state, action) => ({
  ...state,
  programDownloadUrl: null,
});
export const getProgramsListDownloadUrlRequest = (state, action) => ({
  ...state,
  programDownloadUrl: null,
});
export const getProgramsListDownloadUrlSuccess = (state, action) => ({
  ...state,
  programDownloadUrl: action.url,
});

export const getDirectApplicationListActivitiesFailure = (
  state,
  action,
) => ({
  ...state,
  activities: [],
  isLoadingActivities: false,
});
export const getDirectApplicationListActivitiesRequest = (
  state,
  action,
) => ({
  ...state,
  activities: [],
  isLoadingActivities: true,
});
export const getDirectApplicationListActivitiesSuccess = (
  state,
  action,
) => ({
  ...state,
  activities: action.activities,
  isLoadingActivities: false,
});

export const getProgramsFilterUniversitiesFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getProgramsFilterUniversitiesRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getProgramsFilterUniversitiesSuccess = (
  state,
  action,
) => ({
  ...state,
  programsFilterUniversities: action.universities,
});

export const getProgramsFilterStatusesFailure = (state, action) => ({
  ...state,
});
export const getProgramsFilterStatusesRequest = (state, action) => ({
  ...state,
});
export const getProgramsFilterStatusesSuccess = (state, action) => ({
  ...state,
  programsFilterStatuses: action.statuses,
});

export const getProgramsFilterRoundsFailure = (state, action) => ({
  ...state,
});
export const getProgramsFilterRoundsRequest = (state, action) => ({
  ...state,
});
export const getProgramsFilterRoundsSuccess = (state, action) => ({
  ...state,
  programsFilterRounds: action.rounds,
});

export const setApplicationStatusFailure = (state, action) => ({
  ...state,
});
export const setApplicationStatusRequest = (state, action) => ({
  ...state,
});
export const setApplicationStatusSuccess = (state, action) => ({
  ...state,
  statusUpdated: true,
});

export const submitApplicationFailure = (state, action) => ({
  ...state,
});
export const submitApplicationRequest = (state, action) => ({
  ...state,
});
export const submitApplicationSuccess = (state, action) => ({
  ...state,
});

export const unsubmitApplicationFailure = (state, action) => ({
  ...state,
});
export const unsubmitApplicationRequest = (state, action) => ({
  ...state,
});
export const unsubmitApplicationSuccess = (state, action) => ({
  ...state,
});

export const getDirectApplicationNotesFailure = (state, action) => ({
  ...state,
  notes: "",
  isLoadingNotes: false,
});
export const getDirectApplicationNotesRequest = (state, action) => ({
  ...state,
  notes: "",
  isLoadingNotes: true,
});
export const getDirectApplicationNotesSuccess = (state, action) => ({
  ...state,
  notes: action.notes,
  isLoadingNotes: false,
});

export const saveDirectApplicationNotesFailure = (state, action) => ({
  ...state,
  isSavingNotes: false,
});
export const saveDirectApplicationNotesRequest = (state, action) => ({
  ...state,
  isSavingNotes: true,
});
export const saveDirectApplicationNotesSuccess = (state, action) => ({
  ...state,
  isSavingNotes: false,
});
export const getDirectApplyProgramsCountriesFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyProgramsCountriesRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyProgramsCountriesSuccess = (
  state,
  action,
) => ({
  ...state,
  programCountries: action.countries,
});

export const getDirectApplyProgramsCitizenshipsFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyProgramsCitizenshipsRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyProgramsCitizenshipsSuccess = (
  state,
  action,
) => ({
  ...state,
  programCitizenships: action.citizenships,
});

export const getDirectApplyProgramsPartnersFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyProgramsPartnersRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyProgramsPartnersSuccess = (
  state,
  action,
) => ({
  ...state,
  programPartners: action.partners,
});

export const getUcasQuickSubmit = (state, action) => ({
  ...state,
});
export const getUcasQuickSubmitSuccess = (state, action) => ({
  ...state,
  ucasQuickSubmit: action.info,
});
export const getUcasQuickSubmitFailure = (state, action) => ({
  ...state,
});

export const getTaggingQuickSubmit = (state, action) => ({
  ...state,
});
export const getTaggingQuickSubmitSuccess = (state, action) => ({
  ...state,
  taggingQuickSubmit: action.info,
});
export const getTaggingQuickSubmitFailure = (state, action) => ({
  ...state,
});

export const requestMarkComplete = (state, action) => ({
  ...state,
});
export const requestMarkCompleteSuccess = (state, action) => ({
  ...state,
  ucasQuickSubmit: action.info,
});
export const requestMarkCompleteFailure = (state, action) => ({
  ...state,
});

export const requestMarkTaggingComplete = (state, action) => ({
  ...state,
});
export const requestMarkTaggingCompleteSuccess = (state, action) => ({
  ...state,
  taggingQuickSubmit: action.info,
});
export const requestMarkTaggingCompleteFailure = (state, action) => ({
  ...state,
});

export const requestUcasChoices = (state, action) => ({
  ...state,
  isUcasChoicesFilled: true,
});
export const requestUcasChoicesSuccess = (state, action) => ({
  ...state,
  isUcasChoicesFilled: false,
});
export const requestUcasChoicesFailure = (state, action) => ({
  ...state,
  isUcasChoicesFilled: false,
});

export const fillTaggingFormRequest = (state, action) => ({
  ...state,
});
export const fillTaggingFormSuccess = (state, action) => ({
  ...state,
});
export const fillTaggingFormFailure = (state, action) => ({
  ...state,
});

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.CLEAR_APPLICATIONS]: clearApplications,
  [Types.CLEAR_TRANSACTIONS]: clearTransactions,
  [Types.CLEAR_TRANSACTION]: clearTransaction,
  [Types.CLEAR_REFUND_ACTIVITIES]: clearRefundActivities,
  [Types.CLEAR_PROGRAMS]: clearPrograms,

  [Types.GET_APPLICATION_FAILURE]: getApplicationFailure,
  [Types.GET_APPLICATION_REQUEST]: getApplicationRequest,
  [Types.GET_APPLICATION_SUCCESS]: getApplicationSuccess,

  [Types.UPDATE_APPLICATION_UCAS_DATA_FAILURE]:
    updateApplicationUcasDataFailure,
  [Types.UPDATE_APPLICATION_UCAS_DATA_REQUEST]:
    updateApplicationUcasDataRequest,
  [Types.UPDATE_APPLICATION_UCAS_DATA_SUCCESS]:
    updateApplicationUcasDataSuccess,

  [Types.UPDATED_UCAS_CREDENTIAL_FAILURE]:
    updatedUcasCredentialFailure,
  [Types.UPDATED_UCAS_CREDENTIAL_REQUEST]:
    updatedUcasCredentialRequest,
  [Types.UPDATED_UCAS_CREDENTIAL_SUCCESS]:
    updatedUcasCredentialSuccess,

  [Types.GET_DOCUMENT_LIST_FAILURE]: getDocumentListFailure,
  [Types.GET_DOCUMENT_LIST_REQUEST]: getDocumentListRequest,
  [Types.GET_DOCUMENT_LIST_SUCCESS]: getDocumentListSuccess,

  [Types.GET_FOLLOW_UPS_FAILURE]: getFollowUpsFailure,
  [Types.GET_FOLLOW_UPS_REQUEST]: getFollowUpsRequest,
  [Types.GET_FOLLOW_UPS_SUCCESS]: getFollowUpsSuccess,

  [Types.ADD_FOLLOW_UP_FAILURE]: addFollowUpFailure,
  [Types.ADD_FOLLOW_UP_REQUEST]: addFollowUpRequest,
  [Types.ADD_FOLLOW_UP_SUCCESS]: addFollowUpSuccess,

  [Types.UPLOAD_COUNSELOR_DOCUMENT_FAILURE]:
    uploadCounselorDocumentFailure,
  [Types.UPLOAD_COUNSELOR_DOCUMENT_REQUEST]:
    uploadCounselorDocumentRequest,
  [Types.UPLOAD_COUNSELOR_DOCUMENT_SUCCESS]:
    uploadCounselorDocumentSuccess,

  [Types.UPLOAD_CIALFO_DOCUMENT_FAILURE]: uploadCialfoDocumentFailure,
  [Types.UPLOAD_CIALFO_DOCUMENT_REQUEST]: uploadCialfoDocumentRequest,
  [Types.UPLOAD_CIALFO_DOCUMENT_SUCCESS]: uploadCialfoDocumentSuccess,

  [Types.DELETE_CIALFO_DOCUMENT_FAILURE]: deleteCialfoDocumentFailure,
  [Types.DELETE_CIALFO_DOCUMENT_REQUEST]: deleteCialfoDocumentRequest,
  [Types.DELETE_CIALFO_DOCUMENT_SUCCESS]: deleteCialfoDocumentSuccess,

  [Types.GET_APPLICATIONS_FAILURE]: getApplicationsFailure,
  [Types.GET_APPLICATIONS_REQUEST]: getApplicationsRequest,
  [Types.GET_APPLICATIONS_SUCCESS]: getApplicationsSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_COUNTRIES_FAILURE]:
    getDirectApplyFilterCountriesFailure,
  [Types.GET_DIRECT_APPLY_FILTER_COUNTRIES_REQUEST]:
    getDirectApplyFilterCountriesRequest,
  [Types.GET_DIRECT_APPLY_FILTER_COUNTRIES_SUCCESS]:
    getDirectApplyFilterCountriesSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_COURSES_FAILURE]:
    getDirectApplyFilterCoursesFailure,
  [Types.GET_DIRECT_APPLY_FILTER_COURSES_REQUEST]:
    getDirectApplyFilterCoursesRequest,
  [Types.GET_DIRECT_APPLY_FILTER_COURSES_SUCCESS]:
    getDirectApplyFilterCoursesSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_PROGRAMS_FAILURE]:
    getDirectApplyFilterProgramsFailure,
  [Types.GET_DIRECT_APPLY_FILTER_PROGRAMS_REQUEST]:
    getDirectApplyFilterProgramsRequest,
  [Types.GET_DIRECT_APPLY_FILTER_PROGRAMS_SUCCESS]:
    getDirectApplyFilterProgramsSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_RESULTS_FAILURE]:
    getDirectApplyFilterResultsFailure,
  [Types.GET_DIRECT_APPLY_FILTER_RESULTS_REQUEST]:
    getDirectApplyFilterResultsRequest,
  [Types.GET_DIRECT_APPLY_FILTER_RESULTS_SUCCESS]:
    getDirectApplyFilterResultsSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_STATUS_FAILURE]:
    getDirectApplyFilterStatusFailure,
  [Types.GET_DIRECT_APPLY_FILTER_STATUS_REQUEST]:
    getDirectApplyFilterStatusRequest,
  [Types.GET_DIRECT_APPLY_FILTER_STATUS_SUCCESS]:
    getDirectApplyFilterStatusSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_FEE_STATUS_FAILURE]:
    getDirectApplyFilterFeeStatusFailure,
  [Types.GET_DIRECT_APPLY_FILTER_FEE_STATUS_REQUEST]:
    getDirectApplyFilterFeeStatusRequest,
  [Types.GET_DIRECT_APPLY_FILTER_FEE_STATUS_SUCCESS]:
    getDirectApplyFilterFeeStatusSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_FEE_WAIVED_FAILURE]:
    getDirectApplyFilterFeeWaivedFailure,
  [Types.GET_DIRECT_APPLY_FILTER_FEE_WAIVED_REQUEST]:
    getDirectApplyFilterFeeWaivedRequest,
  [Types.GET_DIRECT_APPLY_FILTER_FEE_WAIVED_SUCCESS]:
    getDirectApplyFilterFeeWaivedSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_SCHOOLS_FAILURE]:
    getDirectApplyFilterSchoolsFailure,
  [Types.GET_DIRECT_APPLY_FILTER_SCHOOLS_REQUEST]:
    getDirectApplyFilterSchoolsRequest,
  [Types.GET_DIRECT_APPLY_FILTER_SCHOOLS_SUCCESS]:
    getDirectApplyFilterSchoolsSuccess,

  [Types.GET_DIRECT_APPLY_LIST_DOWNLOAD_URL_FAILURE]:
    getDirectApplyListDownloadUrlFailure,
  [Types.GET_DIRECT_APPLY_LIST_DOWNLOAD_URL_REQUEST]:
    getDirectApplyListDownloadUrlRequest,
  [Types.GET_DIRECT_APPLY_LIST_DOWNLOAD_URL_SUCCESS]:
    getDirectApplyListDownloadUrlSuccess,

  [Types.GET_TRANSACTIONS_FAILURE]: getTransactionsFailure,
  [Types.GET_TRANSACTIONS_REQUEST]: getTransactionsRequest,
  [Types.GET_TRANSACTIONS_SUCCESS]: getTransactionsSuccess,

  [Types.GET_TRANSACTION_FAILURE]: getTransactionFailure,
  [Types.GET_TRANSACTION_REQUEST]: getTransactionRequest,
  [Types.GET_TRANSACTION_SUCCESS]: getTransactionSuccess,

  [Types.REFUND_FAILURE]: refundFailure,
  [Types.REFUND_REQUEST]: refundRequest,
  [Types.REFUND_SUCCESS]: refundSuccess,

  [Types.GET_PROGRAMS_FAILURE]: getProgramsFailure,
  [Types.GET_PROGRAMS_REQUEST]: getProgramsRequest,
  [Types.GET_PROGRAMS_SUCCESS]: getProgramsSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_UNIVERSITIES_FAILURE]:
    getDirectApplyFilterUniversitiesFailure,
  [Types.GET_DIRECT_APPLY_FILTER_UNIVERSITIES_REQUEST]:
    getDirectApplyFilterUniversitiesRequest,
  [Types.GET_DIRECT_APPLY_FILTER_UNIVERSITIES_SUCCESS]:
    getDirectApplyFilterUniversitiesSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_TRANSACTIONS_UNIVERSITIES_FAILURE]:
    getDirectApplyFilterTransactionsUniversitiesFailure,
  [Types.GET_DIRECT_APPLY_FILTER_TRANSACTIONS_UNIVERSITIES_REQUEST]:
    getDirectApplyFilterTransactionsUniversitiesRequest,
  [Types.GET_DIRECT_APPLY_FILTER_TRANSACTIONS_UNIVERSITIES_SUCCESS]:
    getDirectApplyFilterTransactionsUniversitiesSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_PARTNERS_FAILURE]:
    getDirectApplyFilterPartnersFailure,
  [Types.GET_DIRECT_APPLY_FILTER_PARTNERS_REQUEST]:
    getDirectApplyFilterPartnersRequest,
  [Types.GET_DIRECT_APPLY_FILTER_PARTNERS_SUCCESS]:
    getDirectApplyFilterPartnersSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_PROGRAM_TYPES_FAILURE]:
    getDirectApplyFilterProgramTypesFailure,
  [Types.GET_DIRECT_APPLY_FILTER_PROGRAM_TYPES_REQUEST]:
    getDirectApplyFilterProgramTypesRequest,
  [Types.GET_DIRECT_APPLY_FILTER_PROGRAM_TYPES_SUCCESS]:
    getDirectApplyFilterProgramTypesSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_PROGRAM_SUBMISSION_TYPES_FAILURE]:
    getDirectApplyFilterProgramSubmissionTypesFailure,
  [Types.GET_DIRECT_APPLY_FILTER_PROGRAM_SUBMISSION_TYPES_REQUEST]:
    getDirectApplyFilterProgramSubmissionTypesRequest,
  [Types.GET_DIRECT_APPLY_FILTER_PROGRAM_SUBMISSION_TYPES_SUCCESS]:
    getDirectApplyFilterProgramSubmissionTypesSuccess,

  [Types.UPDATE_APPLICATION_STATUS_FAILURE]:
    updateApplicationStatusFailure,
  [Types.UPDATE_APPLICATION_STATUS_REQUEST]:
    updateApplicationStatusRequest,
  [Types.UPDATE_APPLICATION_STATUS_SUCCESS]:
    updateApplicationStatusSuccess,

  [Types.UPDATE_APPLICATION_RESULT_FAILURE]:
    updateApplicationResultFailure,
  [Types.UPDATE_APPLICATION_RESULT_REQUEST]:
    updateApplicationResultRequest,
  [Types.UPDATE_APPLICATION_RESULT_SUCCESS]:
    updateApplicationResultSuccess,

  [Types.UPDATE_APPLICATION_DEPOSIT_PAID_FAILURE]:
    updateApplicationDepositPaidFailure,
  [Types.UPDATE_APPLICATION_DEPOSIT_PAID_REQUEST]:
    updateApplicationDepositPaidRequest,
  [Types.UPDATE_APPLICATION_DEPOSIT_PAID_SUCCESS]:
    updateApplicationDepositPaidSuccess,

  [Types.GET_DIRECT_APPLICATION_RESULTS_FAILURE]:
    getDirectApplicationResultsFailure,
  [Types.GET_DIRECT_APPLICATION_RESULTS_REQUEST]:
    getDirectApplicationResultsRequest,
  [Types.GET_DIRECT_APPLICATION_RESULTS_SUCCESS]:
    getDirectApplicationResultsSuccess,

  [Types.GET_DIRECT_APPLICATION_STATUS_LIST_FAILURE]:
    getDirectApplicationStatusListFailure,
  [Types.GET_DIRECT_APPLICATION_STATUS_LIST_REQUEST]:
    getDirectApplicationStatusListRequest,
  [Types.GET_DIRECT_APPLICATION_STATUS_LIST_SUCCESS]:
    getDirectApplicationStatusListSuccess,

  [Types.GET_DIRECT_APPLICATION_RESULT_LIST_FAILURE]:
    getDirectApplicationResultListFailure,
  [Types.GET_DIRECT_APPLICATION_RESULT_LIST_REQUEST]:
    getDirectApplicationResultListRequest,
  [Types.GET_DIRECT_APPLICATION_RESULT_LIST_SUCCESS]:
    getDirectApplicationResultListSuccess,

  [Types.DELETE_DIRECT_APPLICATION_DOCUMENT_FAILURE]:
    deleteDirectApplicationDocumentFailure,
  [Types.DELETE_DIRECT_APPLICATION_DOCUMENT_REQUEST]:
    deleteDirectApplicationDocumentRequest,
  [Types.DELETE_DIRECT_APPLICATION_DOCUMENT_SUCCESS]:
    deleteDirectApplicationDocumentSuccess,

  [Types.GET_DIRECT_APPLICATION_ACTIVITIES_FAILURE]:
    getDirectApplicationListActivitiesFailure,
  [Types.GET_DIRECT_APPLICATION_ACTIVITIES_REQUEST]:
    getDirectApplicationListActivitiesRequest,
  [Types.GET_DIRECT_APPLICATION_ACTIVITIES_SUCCESS]:
    getDirectApplicationListActivitiesSuccess,

  [Types.GET_PROGRAMS_LIST_DOWNLOAD_URL_FAILURE]:
    getProgramsListDownloadUrlFailure,
  [Types.GET_PROGRAMS_LIST_DOWNLOAD_URL_REQUEST]:
    getProgramsListDownloadUrlRequest,
  [Types.GET_PROGRAMS_LIST_DOWNLOAD_URL_SUCCESS]:
    getProgramsListDownloadUrlSuccess,

  [Types.GET_PROGRAMS_FILTER_UNIVERSITIES_FAILURE]:
    getProgramsFilterUniversitiesFailure,
  [Types.GET_PROGRAMS_FILTER_UNIVERSITIES_REQUEST]:
    getProgramsFilterUniversitiesRequest,
  [Types.GET_PROGRAMS_FILTER_UNIVERSITIES_SUCCESS]:
    getProgramsFilterUniversitiesSuccess,

  [Types.GET_PROGRAMS_FILTER_STATUSES_FAILURE]:
    getProgramsFilterStatusesFailure,
  [Types.GET_PROGRAMS_FILTER_STATUSES_REQUEST]:
    getProgramsFilterStatusesRequest,
  [Types.GET_PROGRAMS_FILTER_STATUSES_SUCCESS]:
    getProgramsFilterStatusesSuccess,

  [Types.GET_PROGRAMS_FILTER_ROUNDS_FAILURE]:
    getProgramsFilterRoundsFailure,
  [Types.GET_PROGRAMS_FILTER_ROUNDS_REQUEST]:
    getProgramsFilterRoundsRequest,
  [Types.GET_PROGRAMS_FILTER_ROUNDS_SUCCESS]:
    getProgramsFilterRoundsSuccess,

  [Types.SET_APPLICATION_STATUS_FAILURE]: setApplicationStatusFailure,
  [Types.SET_APPLICATION_STATUS_REQUEST]: setApplicationStatusRequest,
  [Types.SET_APPLICATION_STATUS_SUCCESS]: setApplicationStatusSuccess,

  [Types.SUBMIT_APPLICATION_FAILURE]: submitApplicationFailure,
  [Types.SUBMIT_APPLICATION_REQUEST]: submitApplicationRequest,
  [Types.SUBMIT_APPLICATION_SUCCESS]: submitApplicationSuccess,

  [Types.UNSUBMIT_APPLICATION_FAILURE]: unsubmitApplicationFailure,
  [Types.UNSUBMIT_APPLICATION_REQUEST]: unsubmitApplicationRequest,
  [Types.UNSUBMIT_APPLICATION_SUCCESS]: unsubmitApplicationSuccess,

  [Types.GET_DIRECT_APPLICATION_NOTES_FAILURE]:
    getDirectApplicationNotesFailure,
  [Types.GET_DIRECT_APPLICATION_NOTES_REQUEST]:
    getDirectApplicationNotesRequest,
  [Types.GET_DIRECT_APPLICATION_NOTES_SUCCESS]:
    getDirectApplicationNotesSuccess,

  [Types.SAVE_DIRECT_APPLICATION_NOTES_FAILURE]:
    saveDirectApplicationNotesFailure,
  [Types.SAVE_DIRECT_APPLICATION_NOTES_REQUEST]:
    saveDirectApplicationNotesRequest,
  [Types.SAVE_DIRECT_APPLICATION_NOTES_SUCCESS]:
    saveDirectApplicationNotesSuccess,
  [Types.GET_DIRECT_APPLY_PROGRAMS_COUNTRIES_FAILURE]:
    getDirectApplyProgramsCountriesFailure,
  [Types.GET_DIRECT_APPLY_PROGRAMS_COUNTRIES_REQUEST]:
    getDirectApplyProgramsCountriesRequest,
  [Types.GET_DIRECT_APPLY_PROGRAMS_COUNTRIES_SUCCESS]:
    getDirectApplyProgramsCountriesSuccess,

  [Types.GET_DIRECT_APPLY_PROGRAMS_CITIZENSHIPS_FAILURE]:
    getDirectApplyProgramsCitizenshipsFailure,
  [Types.GET_DIRECT_APPLY_PROGRAMS_CITIZENSHIPS_REQUEST]:
    getDirectApplyProgramsCitizenshipsRequest,
  [Types.GET_DIRECT_APPLY_PROGRAMS_CITIZENSHIPS_SUCCESS]:
    getDirectApplyProgramsCitizenshipsSuccess,

  [Types.GET_DIRECT_APPLY_PROGRAMS_PARTNERS_FAILURE]:
    getDirectApplyProgramsPartnersFailure,
  [Types.GET_DIRECT_APPLY_PROGRAMS_PARTNERS_REQUEST]:
    getDirectApplyProgramsPartnersRequest,
  [Types.GET_DIRECT_APPLY_PROGRAMS_PARTNERS_SUCCESS]:
    getDirectApplyProgramsPartnersSuccess,

  [Types.GET_UCAS_QUICK_SUBMIT]: getUcasQuickSubmit,
  [Types.GET_UCAS_QUICK_SUBMIT_SUCCESS]: getUcasQuickSubmitSuccess,
  [Types.GET_UCAS_QUICK_SUBMIT_FAILURE]: getUcasQuickSubmitFailure,

  [Types.GET_TAGGING_QUICK_SUBMIT]: getTaggingQuickSubmit,
  [Types.GET_TAGGING_QUICK_SUBMIT_SUCCESS]:
    getTaggingQuickSubmitSuccess,
  [Types.GET_TAGGING_QUICK_SUBMIT_FAILURE]:
    getTaggingQuickSubmitFailure,

  [Types.REQUEST_MARK_COMPLETE]: requestMarkComplete,
  [Types.REQUEST_MARK_COMPLETE_SUCCESS]: requestMarkCompleteSuccess,
  [Types.REQUEST_MARK_COMPLETE_FAILURE]: requestMarkCompleteFailure,

  [Types.REQUEST_MARK_TAGGING_COMPLETE]: requestMarkTaggingComplete,
  [Types.REQUEST_MARK_TAGGING_COMPLETE_SUCCESS]:
    requestMarkTaggingCompleteSuccess,
  [Types.REQUEST_MARK_TAGGING_COMPLETE_FAILURE]:
    requestMarkTaggingCompleteFailure,

  [Types.REQUEST_UCAS_CHOICES]: requestUcasChoices,
  [Types.REQUEST_UCAS_CHOICES_SUCCESS]: requestUcasChoicesSuccess,
  [Types.REQUEST_UCAS_CHOICES_FAILURE]: requestUcasChoicesFailure,

  [Types.FILL_TAGGING_FORM_REQUEST]: fillTaggingFormRequest,
  [Types.FILL_TAGGING_FORM_SUCCESS]: fillTaggingFormSuccess,
  [Types.FILL_TAGGING_FORM_FAILURE]: fillTaggingFormFailure,

  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
