import { call, put, takeEvery, select } from "redux-saga/effects";
import { DirectApplyCreators, DirectApplyTypes } from "../actions";
import {
  getApplication,
  updateApplicationUcasData,
  updatedUcasCredential,
  getDocumentList,
  addFollowUp,
  getFollowUps,
  getApplications,
  getDirectApplyFilterCountries,
  getDirectApplyFilterCourses,
  getDirectApplyFilterPrograms,
  getDirectApplyFilterResults,
  getDirectApplyFilterStatus,
  getDirectApplyFilterFeeWaived,
  getDirectApplyFilterSchools,
  getTransaction,
  getTransactions,
  getRefund,
  getPrograms,
  getDirectApplyFilterUniversitites,
  getDirectApplyFilterTransactionsUniversitites,
  getDirectApplyFilterPartners,
  getDirectApplicationActivities,
  getPartnerProgramUniversities,
  getProgramsFilterStatuses,
  getProgramsFilterRounds,
  getProgramsTypes,
  getProgramsSubmissionTypes,
  setApplicationStatus,
  submitApplication,
  unsubmitApplication,
  saveDirectApplicationNotes,
  getDirectApplicationNotes,
  getDirectApplicationStatusList,
  getDirectApplicationResultList,
  updateApplication,
  getDirectApplyFilterProgramsPartners,
  getDirectApplyFilterProgramsCitizenships,
  getDirectApplyFilterProgramsCountries,
  getPartnerSso,
  getDirectApplyFilterFeeStatus,
  uploadCounselorDocument,
  uploadCialfoDocument,
  deleteCialfoDocument,
  getUcasQuickSubmit,
  getTaggingQuickSubmit,
  finalizeTask,
  markTaggingComplete,
  getUcasChoices,
  fillTaggingForm,
} from "../../web-services";
import _ from "lodash";
import moment from "moment";
import { Notification } from "../../helpers";

const authTokenSelector = state => state.user.authToken;
const sortableColumns = {
  students: "first_name",
  program: "program_name",
  program_type_id: "program_types.code",
  submission_type_id: "program_submission_types.code",
  created_at: "created_at",
  start_date: "partner_programs.start_date",
  fee_waived: "fee_waived",
  status: "status",
  result: "result",
  updated_at: "student_applications.updated_at",
};

export function* watchDirectApplyRequests() {
  yield takeEvery(
    DirectApplyTypes.GET_TRANSACTION_REQUEST,
    requestGetTransaction,
  );
  yield takeEvery(
    DirectApplyTypes.GET_APPLICATION_REQUEST,
    requestGetApplication,
  );
  yield takeEvery(
    DirectApplyTypes.UPDATE_APPLICATION_UCAS_DATA_REQUEST,
    requestUpdateApplicationUcasData,
  );
  yield takeEvery(
    DirectApplyTypes.UPDATED_UCAS_CREDENTIAL_REQUEST,
    requestUpdatedUcasCredential,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DOCUMENT_LIST_REQUEST,
    requestDocumentList,
  );
  yield takeEvery(
    DirectApplyTypes.GET_FOLLOW_UPS_REQUEST,
    requestFollowUps,
  );
  yield takeEvery(
    DirectApplyTypes.ADD_FOLLOW_UP_REQUEST,
    requestFollowUp,
  );
  yield takeEvery(
    DirectApplyTypes.UPLOAD_COUNSELOR_DOCUMENT_REQUEST,
    requestUploadCounselorDocument,
  );

  yield takeEvery(
    DirectApplyTypes.UPLOAD_CIALFO_DOCUMENT_REQUEST,
    requestUploadCialfoDocument,
  );
  yield takeEvery(
    DirectApplyTypes.DELETE_CIALFO_DOCUMENT_REQUEST,
    requestDeleteCialfoDocument,
  );
  yield takeEvery(
    DirectApplyTypes.GET_APPLICATIONS_REQUEST,
    requestGetApplications,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_COUNTRIES_REQUEST,
    requestGetCountriesFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_COURSES_REQUEST,
    requestGetCoursesFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_PROGRAMS_REQUEST,
    requestGetProgramsFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_RESULTS_REQUEST,
    requestGetResultsFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_STATUS_REQUEST,
    requestGetStatusFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_FEE_STATUS_REQUEST,
    requestGetFeeStatusFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_FEE_WAIVED_REQUEST,
    requestGetFeeWaivedFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_SCHOOLS_REQUEST,
    requestGetSchoolsFilter,
  );
  yield takeEvery(
    DirectApplyCreators.getDirectApplyListDownloadUrlRequest().type,
    requestGetListDownloadURL,
  );
  yield takeEvery(
    DirectApplyTypes.GET_TRANSACTIONS_REQUEST,
    requestGetTransactions,
  );
  yield takeEvery(DirectApplyTypes.REFUND_REQUEST, requestRefund);
  yield takeEvery(
    DirectApplyTypes.GET_PROGRAMS_REQUEST,
    requestGetPrograms,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_UNIVERSITIES_REQUEST,
    requestGetUniversitiesFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_TRANSACTIONS_UNIVERSITIES_REQUEST,
    requestGetTransactionsUniversitiesFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_PARTNERS_REQUEST,
    requestGetPartnersFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_PROGRAM_TYPES_REQUEST,
    requestGetProgramTypesFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_PROGRAM_SUBMISSION_TYPES_REQUEST,
    requestGetProgramSubmissionTypesFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLICATION_ACTIVITIES_REQUEST,
    requestGetDirectApplicationActivities,
  );
  yield takeEvery(
    DirectApplyTypes.GET_PROGRAMS_LIST_DOWNLOAD_URL_REQUEST,
    requestGetProgramsDownloadURL,
  );
  yield takeEvery(
    DirectApplyTypes.GET_PROGRAMS_FILTER_UNIVERSITIES_REQUEST,
    requestGetProgramsFilterUniversities,
  );
  yield takeEvery(
    DirectApplyTypes.GET_PROGRAMS_FILTER_STATUSES_REQUEST,
    requestGetProgramsFilterStatuses,
  );
  yield takeEvery(
    DirectApplyTypes.GET_PROGRAMS_FILTER_ROUNDS_REQUEST,
    requestGetProgramsFilterRounds,
  );
  yield takeEvery(
    DirectApplyTypes.SET_APPLICATION_STATUS_REQUEST,
    requestSetApplicationStatus,
  );
  yield takeEvery(
    DirectApplyTypes.SUBMIT_APPLICATION_REQUEST,
    requestSubmitApplication,
  );
  yield takeEvery(
    DirectApplyTypes.UNSUBMIT_APPLICATION_REQUEST,
    requestUnsubmitApplication,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLICATION_NOTES_REQUEST,
    requestGetDirectApplicationNotes,
  );
  yield takeEvery(
    DirectApplyTypes.SAVE_DIRECT_APPLICATION_NOTES_REQUEST,
    requestSaveDirectApplicationNotes,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLICATION_STATUS_LIST_REQUEST,
    requestGetDirectApplicationStatusList,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLICATION_RESULT_LIST_REQUEST,
    requestGetDirectApplicationResultList,
  );
  yield takeEvery(
    DirectApplyTypes.UPDATE_APPLICATION_STATUS_REQUEST,
    requestUpdateApplicationStatus,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_PROGRAMS_COUNTRIES_REQUEST,
    requestGetProgramsCountries,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_PROGRAMS_CITIZENSHIPS_REQUEST,
    requestGetProgramsCitizenships,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_PROGRAMS_PARTNERS_REQUEST,
    requestGetProgramsPartners,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_SSO_REQUEST,
    requestGetPartnerSso,
  );
  yield takeEvery(
    DirectApplyTypes.GET_UCAS_QUICK_SUBMIT,
    requestUcasQuickSubmit,
  );
  yield takeEvery(
    DirectApplyTypes.GET_TAGGING_QUICK_SUBMIT,
    requestTaggingQuickSubmit,
  );
  yield takeEvery(
    DirectApplyTypes.REQUEST_MARK_COMPLETE,
    requestMarkComplete,
  );
  yield takeEvery(
    DirectApplyTypes.REQUEST_MARK_TAGGING_COMPLETE,
    requestMarkTaggingComplete,
  );
  yield takeEvery(
    DirectApplyTypes.REQUEST_UCAS_CHOICES,
    requestUcasChoices,
  );
  yield takeEvery(
    DirectApplyTypes.FILL_TAGGING_FORM_REQUEST,
    requestFillTaggingForm,
  );
}

const documentUploadedRange = document_upload_date => {
  return document_upload_date
    ? {
        document_uploaded_from: moment(document_upload_date.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss"),
        document_uploaded_to: moment(document_upload_date.endDate)
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss"),
      }
    : {};
};

function* requestGetApplication(action) {
  try {
    const { id } = action;
    const authToken = yield select(authTokenSelector);

    const response = yield call(getApplication, authToken, id);
    yield put(
      DirectApplyCreators.getApplicationSuccess(
        response.data.application,
      ),
    );
  } catch (error) {
    yield put(DirectApplyCreators.getApplicationFailure());
  }
}

function* requestUpdateApplicationUcasData(action) {
  try {
    const { userId, data } = action;
    const authToken = yield select(authTokenSelector);

    const response = yield call(
      updateApplicationUcasData,
      authToken,
      userId,
      data,
    );
    yield put(
      DirectApplyCreators.updateApplicationUcasDataSuccess(
        response.data.application,
      ),
    );
    if (response?.data) {
      Notification.success(response?.data.message);
    }
  } catch (error) {
    yield put(DirectApplyCreators.updateApplicationUcasDataFailure());
  }
}

function* requestUpdatedUcasCredential(action) {
  try {
    const { userId, data } = action;
    const authToken = yield select(authTokenSelector);

    const response = yield call(
      updatedUcasCredential,
      authToken,
      userId,
      data,
    );
    yield put(
      DirectApplyCreators.updatedUcasCredentialSuccess(response.data),
    );
  } catch (error) {
    yield put(DirectApplyCreators.updatedUcasCredentialFailure());
  }
}

function* requestDocumentList() {
  try {
    const authToken = yield select(authTokenSelector);

    const response = yield call(getDocumentList, authToken);
    yield put(
      DirectApplyCreators.getDocumentListSuccess(
        response.data.documents,
      ),
    );
  } catch (error) {
    yield put(DirectApplyCreators.getDocumentListFailure());
  }
}

function* requestFollowUps(action) {
  const { applicationId } = action;
  try {
    const authToken = yield select(authTokenSelector);

    const response = yield call(
      getFollowUps,
      authToken,
      applicationId,
    );
    yield put(DirectApplyCreators.getFollowUpsSuccess(response.data));
  } catch (error) {
    yield put(DirectApplyCreators.getFollowUpsFailure());
  }
}

function* requestFollowUp(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const { data } = action;
    const response = yield call(addFollowUp, authToken, data);
    yield put(DirectApplyCreators.addFollowUpSuccess(response.data));
    if (response.data) {
      const successMessage =
        data.follow_ups.doc_types.length > 0
          ? "Request for documents sent"
          : "Request for information sent";
      Notification.success(successMessage);
    }
  } catch (error) {
    yield put(DirectApplyCreators.addFollowUpFailure());
  }
}

function* requestUploadCounselorDocument(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const { userId, data } = action;
    const { file, file_name, document_type } = data;
    const params = new FormData();
    params.append("file", file);
    params.append("document_type", document_type);
    params.append("file_name", file_name);
    params.append("uploaded_by_cialfo", true);
    const response = yield call(
      uploadCounselorDocument,
      authToken,
      userId,
      params,
    );
    yield put(
      DirectApplyCreators.uploadCounselorDocumentSuccess(
        response.data,
      ),
    );
    if (response.data) {
      Notification.success(`${document_type} uploaded successfully`);
    }
  } catch (error) {
    yield put(DirectApplyCreators.uploadCounselorDocumentFailure());
  }
}

function* requestUploadCialfoDocument(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const { applicationId, data } = action;
    const { file, file_name } = data;
    const params = new FormData();
    params.append("document[file]", file);
    params.append("document[type]", "Cialfo Internal Documents");
    params.append("document[name]", file_name);
    params.append("student_application_id", applicationId);
    const response = yield call(
      uploadCialfoDocument,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.uploadCialfoDocumentSuccess(response.data),
    );
    if (response.data) {
      Notification.success(`Document uploaded successfully`);
    }
  } catch (error) {
    yield put(DirectApplyCreators.uploadCialfoDocumentFailure());
  }
}

function* requestDeleteCialfoDocument(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const { documentId, applicationId } = action;

    const response = yield call(
      deleteCialfoDocument,
      authToken,
      applicationId,
      documentId,
    );
    yield put(
      DirectApplyCreators.deleteCialfoDocumentSuccess(response.data),
    );
  } catch (error) {
    yield put(DirectApplyCreators.deleteCialfoDocumentFailure());
  }
}

export function* requestGetPartnerSso(action) {
  try {
    const { applicationId, params, partnerId } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getPartnerSso,
      authToken,
      partnerId,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplySsoSuccess(
        response.data.sso_url,
      ),
    );
    window.open(
      `${response.data.sso_url}&redirect_url=direct-apply/applications/${applicationId}`,
      "_blank",
    );
  } catch (error) {
    yield put(DirectApplyCreators.getDirectApplySsoFailure());
  }
}

function* requestUpdateApplicationStatus(action) {
  try {
    const { id, updateData } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      updateApplication,
      authToken,
      id,
      updateData,
    );
    yield put(
      DirectApplyCreators.updateApplicationStatusSuccess(
        response.data,
      ),
    );
  } catch (error) {
    yield put(DirectApplyCreators.updateApplicationStatusFailure());
  }
}

function* requestSetApplicationStatus(action) {
  try {
    const { id, status } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      setApplicationStatus,
      authToken,
      id,
      status,
    );
    yield put(
      DirectApplyCreators.setApplicationStatusSuccess(response.data),
    );
  } catch (error) {
    yield put(DirectApplyCreators.setApplicationStatusFailure());
  }
}

function* requestSubmitApplication(action) {
  try {
    const { id } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(submitApplication, authToken, id);
    yield put(
      DirectApplyCreators.submitApplicationSuccess(response.data),
    );
  } catch (error) {
    yield put(DirectApplyCreators.submitApplicationFailure());
  }
}

function* requestUnsubmitApplication(action) {
  try {
    const { id } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(unsubmitApplication, authToken, id);
    yield put(
      DirectApplyCreators.unsubmitApplicationSuccess(response.data),
    );
  } catch (error) {
    yield put(DirectApplyCreators.unsubmitApplicationFailure());
  }
}

function* requestGetApplications(action) {
  try {
    const { resolve } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const directApplySelector = state => state.directApply;
    const directApplyListStateSelector = state =>
      state.directApplyListState.applicationFilters;
    const { data: currentApplications, params: currentParams } =
      yield select(directApplySelector);
    const {
      filters,
      searchText,
      tab,
      sortBy: { identifier: sortColumn, direction: sortDirection },
      isPast,
    } = yield select(directApplyListStateSelector);

    let countryFilter =
      filters.country && filters.country.length > 0
        ? { country_ids: filters.country.map(country => country.id) }
        : {};
    let coursesFilter =
      filters.course && filters.course.length > 0
        ? { course_ids: filters.course.map(course => course.id) }
        : {};
    let programsFilter =
      filters.program && filters.program.length > 0
        ? { program_ids: filters.program.map(program => program.id) }
        : {};
    let schoolsFilter =
      filters.school && filters.school.length > 0
        ? { school_ids: filters.school.map(school => school.id) }
        : {};
    const feeStatusFilter =
      filters.fee_status && filters.fee_status.length > 0
        ? { fee_status: filters.fee_status.map(status => status.id) }
        : {};
    let universitiesFilter =
      filters.university && filters.university.length > 0
        ? {
            university_ids: filters.university.map(
              university => university.id,
            ),
          }
        : {};
    let statusFilter =
      filters.status && filters.status.length > 0
        ? { status: filters.status.map(status => status.id) }
        : {};
    let feeWaivedFilter =
      filters.fee_waived && filters.fee_waived.length > 0
        ? { fee_waived: filters.fee_waived.map(status => status.id) }
        : {};
    let resultsFilter =
      filters.results && filters.results.length > 0
        ? { result: filters.results.map(result => result.id) }
        : {};

    let programTypeFilter =
      filters.program_types && filters.program_types.length > 0
        ? {
            program_type_ids: filters.program_types.map(
              type => type.id,
            ),
          }
        : {};
    let submissionTypeFilter =
      filters.submission_types && filters.submission_types.length > 0
        ? {
            program_submission_type_ids: filters.submission_types.map(
              type => type.id,
            ),
          }
        : {};
    let partnerFilter =
      filters.partner && filters.partner.length > 0
        ? { partner_ids: filters.partner.map(partner => partner.id) }
        : {};
    const dateRange = filters.application_date
      ? {
          start_date: moment(filters.application_date.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss"),
          end_date: moment(filters.application_date.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss"),
        }
      : {};
    const lastUpdatedDateRange = filters.last_updated
      ? {
          updated_from: moment(filters.last_updated.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss"),
          updated_to: moment(filters.last_updated.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss"),
        }
      : {};
    const approveOnDateRange = filters.approved_at
      ? {
          approved_from: moment(filters.approved_at.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss"),
          approved_to: moment(filters.approved_at.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss"),
        }
      : {};
    const isReviewByCounselor =
      filters.is_reviewed_by_counselor &&
      filters.is_reviewed_by_counselor.length > 0
        ? {
            is_reviewed_by_counselor:
              filters.is_reviewed_by_counselor[0].id,
          }
        : {};
    const isCounselorReviewEnabled =
      filters.is_counselor_review_enabled &&
      filters.is_counselor_review_enabled.length > 0
        ? {
            is_counselor_review_enabled:
              filters.is_counselor_review_enabled[0].id,
          }
        : {};
    const isCounsellorSupportEnabled =
      filters.is_counsellor_support_enabled &&
      filters.is_counsellor_support_enabled.length > 0
        ? {
            is_counsellor_support_enabled:
              filters.is_counsellor_support_enabled[0].id,
          }
        : {};
    const demoApplicationFilter =
      filters.is_demo && filters.is_demo.length > 0
        ? {
            is_demo: filters.is_demo.map(type => type.id),
          }
        : {};
    const params = {
      search_text: searchText,
      filters: {
        ...countryFilter,
        ...coursesFilter,
        ...programsFilter,
        ...schoolsFilter,
        ...statusFilter,
        ...feeWaivedFilter,
        ...resultsFilter,
        ...programTypeFilter,
        ...submissionTypeFilter,
        submitted: tab === "submitted",
        is_past: isPast,
        ...dateRange,
        ...documentUploadedRange(filters.document_upload_date),
        ...universitiesFilter,
        ...partnerFilter,
        ...lastUpdatedDateRange,
        ...approveOnDateRange,
        ...feeStatusFilter,
        ...isReviewByCounselor,
        ...isCounselorReviewEnabled,
        ...isCounsellorSupportEnabled,
        ...demoApplicationFilter,
      },
      sort_params: [
        { sort_by: sortColumn, sort_direction: sortDirection },
      ],
    };

    const shouldLoadMore =
      currentApplications.length > 0 &&
      _.isEqual(_.omit(currentParams, ["page_no"]), params);

    if (!shouldLoadMore)
      yield put(DirectApplyCreators.clearApplications());

    const page = shouldLoadMore ? currentParams.page_no + 1 : 1;
    const requestParams = { ...params };
    requestParams["page_no"] = page;
    const response = yield call(
      getApplications,
      authToken,
      requestParams,
    );
    resolve && resolve();
    const {
      applications,
      matched,
      total,
      past_count,
      students_count,
    } = response.data;
    yield put(
      DirectApplyCreators.getApplicationsSuccess(
        applications,
        matched,
        total,
        past_count,
        requestParams,
        students_count,
      ),
    );
  } catch (error) {
    const { reject } = action;
    reject && reject();
    yield put(DirectApplyCreators.getApplicationsFailure());
  }
}

function* requestGetDirectApplicationActivities(action) {
  try {
    const { applicationId } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplicationActivities,
      authToken,
      applicationId,
    );
    yield put(
      DirectApplyCreators.getDirectApplicationActivitiesSuccess(
        response.data.activities,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplicationActivitiesFailure(),
    );
  }
}

function* requestGetDirectApplicationNotes(action) {
  try {
    const { applicationId } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplicationNotes,
      authToken,
      applicationId,
    );
    yield put(
      DirectApplyCreators.getDirectApplicationNotesSuccess(
        response.data.activities,
      ),
    );
  } catch (error) {
    yield put(DirectApplyCreators.getDirectApplicationNotesFailure());
  }
}

function* requestGetDirectApplicationStatusList(action) {
  try {
    const { applicationId } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplicationStatusList,
      authToken,
      applicationId,
    );
    yield put(
      DirectApplyCreators.getDirectApplicationStatusListSuccess(
        response.data.statuses,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplicationStatusListFailure(),
    );
  }
}

function* requestGetDirectApplicationResultList(action) {
  try {
    const { applicationId } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplicationResultList,
      authToken,
      applicationId,
    );
    yield put(
      DirectApplyCreators.getDirectApplicationResultListSuccess(
        response.data.statuses,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplicationResultListFailure(),
    );
  }
}

function* requestSaveDirectApplicationNotes(action) {
  try {
    const { notes } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      saveDirectApplicationNotes,
      authToken,
      notes,
    );
    yield put(
      DirectApplyCreators.saveDirectApplicationNotesSuccess(
        response.data.activities,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.saveDirectApplicationNotesFailure(),
    );
  }
}

function* requestGetCountriesFilter(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterCountries,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFilterCountriesSuccess(
        response.data.countries,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterCountriesFailure(),
    );
  }
}

function* requestGetProgramsCountries(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterProgramsCountries,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyProgramsCountriesSuccess(
        response.data.countries,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyProgramsCountriesFailure(),
    );
  }
}

function* requestGetProgramsCitizenships(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterProgramsCitizenships,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyProgramsCitizenshipsSuccess(
        response.data.citizenships,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyProgramsCitizenshipsFailure(),
    );
  }
}

function* requestGetProgramsPartners(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterProgramsPartners,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyProgramsPartnersSuccess(
        response.data.partners,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyProgramsPartnersFailure(),
    );
  }
}

function* requestGetCoursesFilter(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterCourses,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFilterCoursesSuccess(
        response.data.courses,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterCoursesFailure(),
    );
  }
}

function* requestGetProgramsFilter(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterPrograms,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFilterProgramsSuccess(
        response.data.programs,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterProgramsFailure(),
    );
  }
}

function* requestGetResultsFilter(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterResults,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFilterResultsSuccess(
        response.data.results,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterResultsFailure(),
    );
  }
}

function* requestGetStatusFilter(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterStatus,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFilterStatusSuccess(
        response.data.statuses,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterStatusFailure(),
    );
  }
}

function* requestGetFeeStatusFilter(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterFeeStatus,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFilterFeeStatusSuccess(
        response.data.statuses,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterFeeStatusFailure(),
    );
  }
}

function* requestGetFeeWaivedFilter(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterFeeWaived,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFilterFeeWaivedSuccess(
        response.data.statuses,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterFeeWaivedFailure(),
    );
  }
}

function* requestGetSchoolsFilter(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterSchools,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFilterSchoolsSuccess(
        response.data.schools,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterSchoolsFailure(),
    );
  }
}

function* requestGetListDownloadURL(action) {
  try {
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const directApplyListStateSelector = state =>
      state.directApplyListState.applicationFilters;
    const {
      filters,
      searchText,
      tab,
      sortBy: { column: sortColumn, direction: sortDirection },
    } = yield select(directApplyListStateSelector);

    const countryFilter =
      filters.country && filters.country.length > 0
        ? { country_ids: filters.country.map(country => country.id) }
        : {};
    const coursesFilter =
      filters.course && filters.course.length > 0
        ? { course_ids: filters.course.map(course => course.id) }
        : {};
    const programsFilter =
      filters.program && filters.program.length > 0
        ? { program_ids: filters.program.map(program => program.id) }
        : {};
    const schoolsFilter =
      filters.school && filters.school.length > 0
        ? { school_ids: filters.school.map(school => school.id) }
        : {};
    const universitiesFilter =
      filters.university && filters.university.length > 0
        ? {
            university_ids: filters.university.map(
              university => university.id,
            ),
          }
        : {};
    const statusFilter =
      filters.status && filters.status.length > 0
        ? { status: filters.status.map(status => status.id) }
        : {};
    const resultsFilter =
      filters.results && filters.results.length > 0
        ? { result: filters.results.map(result => result.id) }
        : {};
    const programTypeFilter =
      filters.program_types && filters.program_types.length > 0
        ? {
            program_type_ids: filters.program_types.map(
              type => type.id,
            ),
          }
        : {};
    const submissionTypeFilter =
      filters.submission_types && filters.submission_types.length > 0
        ? {
            program_submission_type_ids: filters.submission_types.map(
              type => type.id,
            ),
          }
        : {};
    const partnerFilter =
      filters.partner && filters.partner.length > 0
        ? { partner_ids: filters.partner.map(partner => partner.id) }
        : {};
    const dateRange = filters.application_date
      ? {
          start_date: moment(filters.application_date.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss"),
          end_date: moment(filters.application_date.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss"),
        }
      : {};
    const lastUpdatedDateRange = filters.last_updated
      ? {
          updated_from: moment(filters.last_updated.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss"),
          updated_to: moment(filters.last_updated.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss"),
        }
      : {};

    const feeWaivedFilter =
      filters.fee_waived && filters.fee_waived.length > 0
        ? { fee_waived: filters.fee_waived.map(status => status.id) }
        : {};

    const params = {
      download: true,
      search_text: searchText,
      filters: {
        ...countryFilter,
        ...coursesFilter,
        ...programsFilter,
        ...schoolsFilter,
        ...statusFilter,
        ...resultsFilter,
        ...programTypeFilter,
        ...submissionTypeFilter,
        ...partnerFilter,
        ...dateRange,
        ...documentUploadedRange(filters.document_upload_date),
        ...lastUpdatedDateRange,
        ...feeWaivedFilter,
        application_status: tab,
        ...universitiesFilter,
      },
      sort_params: [
        {
          sort_by: sortableColumns[sortColumn],
          sort_direction: sortDirection,
        },
      ],
    };
    const response = yield call(getApplications, authToken, params);
    yield put(
      DirectApplyCreators.getDirectApplyListDownloadUrlSuccess(
        response.data.csv_url,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyListDownloadUrlFailure(),
    );
  }
}

function* requestGetTransactions(action) {
  try {
    const { resolve } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);

    const directApplySelector = state => state.directApply;
    const {
      transactions: currentTransactions,
      transactionsParams: currentParams,
    } = yield select(directApplySelector);
    const directApplyListStateSelector = state =>
      state.directApplyListState.transactionFilters;

    const {
      filters,
      searchText,
      sortBy: { identifier: sortColumn, direction: sortDirection },
    } = yield select(directApplyListStateSelector);

    let universitiesFilter =
      filters.university && filters.university.length > 0
        ? {
            university_ids: filters.university.map(
              university => university.id,
            ),
          }
        : {};
    let payment_statusFilter =
      filters.payment_status && filters.payment_status.length > 0
        ? { conditions: filters.payment_status.map(ps => ps.id) }
        : {};

    const params = {
      search_text: searchText,
      ...payment_statusFilter,
      filters: { ...universitiesFilter },
      sort_params: sortColumn
        ? [{ sort_by: sortColumn, sort_direction: sortDirection }]
        : [],
    };
    const shouldLoadMore =
      currentTransactions.length > 0 &&
      _.isEqual(
        _.omit(currentParams, ["page_no", "per_page"]),
        params,
      );

    const page = shouldLoadMore ? currentParams.page_no + 1 : 1;

    if (!shouldLoadMore)
      yield put(DirectApplyCreators.clearTransactions());

    const requestParams = {
      ...params,
      per_page: 20,
      page_no: page,
    };
    const response = yield call(
      getTransactions,
      authToken,
      requestParams,
    );
    resolve && resolve();
    const { transactions, matched, total } = response.data;
    yield put(
      DirectApplyCreators.getTransactionsSuccess(
        transactions,
        matched,
        total,
        requestParams,
      ),
    );
  } catch (error) {
    const { reject } = action;
    reject && reject();
    yield put(DirectApplyCreators.getTransactionsFailure());
  }
}

export function* requestGetTransaction(action) {
  try {
    const { transactionId } = action;
    const authToken = yield select(authTokenSelector);

    const response = yield call(
      getTransaction,
      authToken,
      transactionId,
    );
    yield put(
      DirectApplyCreators.getTransactionSuccess(response.data),
    );
  } catch (error) {
    yield put(DirectApplyCreators.getTransactionFailure());
  }
}

export function* requestRefund(action) {
  try {
    const { transactionId, params } = action;
    const authToken = yield select(authTokenSelector);

    const response = yield call(
      getRefund,
      authToken,
      transactionId,
      params,
    );
    yield put(DirectApplyCreators.refundSuccess(response.data));
  } catch (error) {
    yield put(DirectApplyCreators.refundFailure());
  }
}

function* requestGetProgramsDownloadURL(action) {
  try {
    const { resolve, params: currentParams } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const requestParams = {
      ...currentParams,
      download: true,
    };
    const response = yield call(
      getPrograms,
      authToken,
      requestParams,
    );
    resolve && resolve();
    yield put(
      DirectApplyCreators.getProgramsListDownloadUrlSuccess(
        response.data.csv_url,
      ),
    );
  } catch (error) {
    const { reject } = action;
    reject && reject();
    yield put(
      DirectApplyCreators.getProgramsListDownloadUrlFailure(),
    );
  }
}

function* requestGetPrograms(action) {
  try {
    const { resolve, params: currentParams } = action;
    const authToken = yield select(authTokenSelector);

    const directApplyProgramsSelector = state => state.directApply;
    const { programs: currentPrograms, params: previousParams } =
      yield select(directApplyProgramsSelector);

    const shouldLoadMore =
      currentPrograms.length > 0 &&
      _.isEqual(
        _.omit(previousParams, ["page_no", "per_page"]),
        currentParams,
      );

    const page = shouldLoadMore ? previousParams.page_no + 1 : 1;

    if (!shouldLoadMore)
      yield put(DirectApplyCreators.clearPrograms());

    const requestParams = {
      per_page: 20,
      ...currentParams,
      page_no: page,
    };
    requestParams["page_no"] = page;

    const response = yield call(
      getPrograms,
      authToken,
      requestParams,
    );
    resolve && resolve();
    const { programs, matched, total } = response.data;
    yield put(
      DirectApplyCreators.getProgramsSuccess(
        programs,
        matched,
        total,
        requestParams,
      ),
    );
  } catch (error) {
    const { reject } = action;
    reject && reject();
    yield put(DirectApplyCreators.getProgramsFailure());
  }
}

function* requestGetUniversitiesFilter(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterUniversitites,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFilterUniversitiesSuccess(
        response.data.universities,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterUniversitiesFailure(),
    );
  }
}

function* requestGetTransactionsUniversitiesFilter(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterTransactionsUniversitites,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFilterTransactionsUniversitiesSuccess(
        response.data.universities,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterTransactionsUniversitiesFailure(),
    );
  }
}

function* requestGetPartnersFilter(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterPartners,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFilterPartnersSuccess(
        response.data.partners,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterPartnersFailure(),
    );
  }
}

function* requestGetProgramTypesFilter(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getProgramsTypes, authToken);

    yield put(
      DirectApplyCreators.getDirectApplyFilterProgramTypesSuccess(
        response.data.program_type,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterProgramTypesFailure(),
    );
  }
}

function* requestGetProgramSubmissionTypesFilter(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getProgramsSubmissionTypes,
      authToken,
    );

    yield put(
      DirectApplyCreators.getDirectApplyFilterProgramSubmissionTypesSuccess(
        response.data.program_submission_type,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterProgramSubmissionTypesFailure(),
    );
  }
}

function* requestGetProgramsFilterUniversities(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getPartnerProgramUniversities,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getProgramsFilterUniversitiesSuccess(
        response.data.universities,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getProgramsFilterUniversitiesFailure(),
    );
  }
}

function* requestGetProgramsFilterStatuses(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getProgramsFilterStatuses,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getProgramsFilterStatusesSuccess(
        response.data.statuses,
      ),
    );
  } catch (error) {
    yield put(DirectApplyCreators.getProgramsFilterStatusesFailure());
  }
}

function* requestGetProgramsFilterRounds(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getProgramsFilterRounds,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getProgramsFilterRoundsSuccess(
        response.data.rounds,
      ),
    );
  } catch (error) {
    yield put(DirectApplyCreators.getProgramsFilterRoundsFailure());
  }
}

function* requestUcasQuickSubmit(action) {
  try {
    const authToken = yield select(state => state.user.authToken);
    const response = yield call(
      getUcasQuickSubmit,
      authToken,
      action.appId,
    );
    yield put(
      DirectApplyCreators.getUcasQuickSubmitSuccess(response.data),
    );
  } catch (error) {
    yield put(DirectApplyCreators.getUcasQuickSubmitFailure(error));
  }
}

function* requestTaggingQuickSubmit(action) {
  try {
    const authToken = yield select(state => state.user.authToken);
    const response = yield call(
      getTaggingQuickSubmit,
      authToken,
      action.appId,
    );
    yield put(
      DirectApplyCreators.getTaggingQuickSubmitSuccess(response.data),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getTaggingQuickSubmitFailure(error),
    );
  }
}

function* requestMarkComplete(action) {
  try {
    const authToken = yield select(state => state.user.authToken);
    const response = yield call(
      finalizeTask,
      authToken,
      action.appId,
      action.params,
    );
    yield put(
      DirectApplyCreators.requestMarkCompleteSuccess(response.data),
    );
  } catch (error) {
    yield put(DirectApplyCreators.requestMarkCompleteFailure(error));
  }
}

function* requestMarkTaggingComplete(action) {
  try {
    const authToken = yield select(state => state.user.authToken);
    const response = yield call(
      markTaggingComplete,
      authToken,
      action.appId,
      action.params,
    );
    yield put(
      DirectApplyCreators.requestMarkTaggingCompleteSuccess(
        response.data,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.requestMarkTaggingCompleteFailure(error),
    );
  }
}

function* requestUcasChoices(action) {
  try {
    const authToken = yield select(state => state.user.authToken);
    const response = yield call(
      getUcasChoices,
      authToken,
      action.appId,
    );
    if (response?.data) {
      Notification.success(response.data?.message);
    }
    yield put(
      DirectApplyCreators.requestUcasChoicesSuccess(response.data),
    );
  } catch (error) {
    yield put(DirectApplyCreators.requestUcasChoicesFailure(error));
  }
}

function* requestFillTaggingForm(action) {
  try {
    const authToken = yield select(state => state.user.authToken);
    const response = yield call(
      fillTaggingForm,
      authToken,
      action.appId,
    );
    if (response?.data) {
      Notification.success(response.data?.message);
    }
    yield put(
      DirectApplyCreators.fillTaggingFormSuccess(response.data),
    );
  } catch (error) {
    yield put(DirectApplyCreators.fillTaggingFormFailure(error));
  }
}
