import React from "react";
import { Format } from "src/helpers";
import { FIELD_TYPE } from "src/constants/applications.constants";
import { DisplayDocuments } from "./display-documents";

const DisplayItem = ({ item, predicted_grades_sent }) => {
  return (
    <>
      {item.field_type === FIELD_TYPE.singleUpload ||
      item.field_type === FIELD_TYPE.multipleUpload ? (
        <DisplayDocuments
          label={item.label}
          value={item.files}
          copyable
        />
      ) : (
        <div>
          <div className="flex-none flex flex-row items-center min-h-42 px-3 py-2 display-field">
            <div className={"flex-none w-56"}>
              <h3 className="text-sm text-gray-700">{item.label}</h3>
            </div>
            <div className={"flex-1 flex flex-row"}>
              <div className={"flex-1 flex flex-row pl-4"}>
                <div className="text-sm font-bold text-gray-900">
                  {item.counselor_upload_required
                    ? "Grade is pending/expected"
                    : Format.displayValue(item.answer_value)}
                </div>
              </div>
            </div>
          </div>
          {item.counselor_upload_required &&
            predicted_grades_sent && (
              <div className="flex-none flex flex-row items-center min-h-42 px-3 py-2 display-field">
                <div className={"flex-none w-56"}>
                  <h3 className="text-sm text-gray-700">
                    Counselor predicted grades{" "}
                  </h3>
                </div>
                <div className={"flex-1 flex flex-row"}>
                  <div className={"flex-1 flex flex-row pl-4"}>
                    <div className="text-sm font-bold text-gray-900">
                      {item.counselor_value || "Not available"}
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      )}
    </>
  );
};

export const DisplayList = ({
  value,
  item_name,
  predicted_grades_sent,
}) => {
  return (
    <div>
      {value.map((items, i) => {
        return (
          <div>
            {items && (
              <div className="border border-gray-300 rounded-lg my-4 px-6 py-4">
                {items.map((item, index) => {
                  return index < 1 ? (
                    <>
                      <h2 class="text-lg text-gray-750 font-bold mt-2 mb-2">
                        {item_name} {i + 1} - {item.answer_value}
                      </h2>
                      <DisplayItem
                        item={item}
                        predicted_grades_sent={predicted_grades_sent}
                      />
                    </>
                  ) : (
                    <>
                      <DisplayItem
                        item={item}
                        predicted_grades_sent={predicted_grades_sent}
                      />
                      {item.field_type === FIELD_TYPE.tabulation &&
                        item.item_questions.length > 0 && (
                          <>
                            <h2 class="text-lg text-gray-750 font-bold mt-2">
                              {`${item.item_name}(s)`}
                            </h2>

                            {item.item_questions?.map(questions => {
                              return (
                                <div>
                                  {questions && (
                                    <div className="py-2">
                                      <div className="border border-gray-300 rounded px-3">
                                        {questions.map(q => {
                                          return (
                                            <DisplayItem
                                              item={q}
                                              predicted_grades_sent={
                                                predicted_grades_sent
                                              }
                                            />
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </>
                        )}
                    </>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
